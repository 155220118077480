.gender-values {
  display: in-line;
  font-family: 'Titillium Web', sans-serif;

}

.gender-group {
  line-height: 29px;
}

.provider-country {
  line-height: 75px;
}

.ant-form-item {
  margin-bottom: 5px !important;
}

.ant-form-item-label label{
  color: #262626;
  font-size: 16px;
  line-height: 22px;
  font-family: 'Titillium Web', sans-serif;
}

.ant-btn-dashed:focus {
  color: rgba(0,0,0, 0.65);
  background-color: #fff;
  border-color: #d9d9d9;
}

.form-heading-step {
  color: rgba(0, 0, 0, 0.65);
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  margin-bottom: 10px;
  font-family: 'Titillium Web', sans-serif;
  text-align: center;
}

.form-heading-step-mobile {
  color: rgba(0, 0, 0, 0.65);
  font-size: 11px;
  font-weight: 500;
  line-height: 15px;
  margin-bottom: 10px;
  font-family: 'Titillium Web', sans-serif;
  text-align: center;
}

.form-heading-text {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 10px;
  font-family: 'Titillium Web', sans-serif;
  text-align: center;
}

.form-description-text {
  color: rgba(0, 0, 0, 0.85);
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 10px;
  font-family: 'Titillium Web', sans-serif;
  text-align: center;
}

.form-field-label {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 15px;
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: 600;
  font-family: 'Titillium Web', sans-serif;
}

.form-field-description {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 16px;
  margin-top: 20px;
  font-family: 'Titillium Web', sans-serif;
  margin-bottom: 10px;
}

.form-centered-row {
  display: flex;
  justify-content: center
}

.submit-button-row {
  margin: 20px 0;
}

.ant-list-item {
  background-color: white !important;
  padding: 10px 40px !important;
  border: 1px solid #e8e8e8;
}

.ant-list-item-meta-title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 10px;
}

.ant-list-item-meta-description {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 16px;
}

.form-field-label-leader {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: 600;
  font-family: 'Titillium Web', sans-serif;
}

.ir-selection-question-text {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.mobile-input-select-field-button {
  min-width: 100% !important;
  padding: 0px 11px !important;
}
.mobile-input-select-field-button-text {
  display: flex !important;
  justify-content: space-between !important;
}

.ant-btn-primary-outline{
  border-color: #15a9e1 !important;
  color: #15a9e1 !important;
}
