.colleague-card {
  background: rgba(255, 255, 255, 0.85);
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  display: flex;

  width: 350px;
  margin: 0 auto;

  @include media-breakpoint-down($lg) {
    width: 320px;
  }

  @include media-breakpoint-down($sm) {
    width: 290px;
  }

  .colleague-card-body {
    padding: 8px;
    flex: 1;

    .colleague-card-name {
      font-family: "Helvetica Neue", sans-serif;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: $primary-color;
    }

    .colleague-card-email {
      font-family: Roboto, sans-serif;
      font-size: 14px;
      line-height: 26px;
    }
  }

  .colleague-card-remove-btn {
    padding: 8px;
    border-left: 1px solid #989898;
    width: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .ant-typography {
      font-family: Roboto, sans-serif;
      font-size: 8px;
      display: inline-block;
      margin-top: 6px;
    }
  }
}
