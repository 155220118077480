/*@import '~antd/dist/antd.css';*/
@import url('https://fonts.googleapis.com/css?family=Noto+Sans');
@import './support_libs/css/layout-styles.css';
@import './support_libs/css/form-styles.css';
@import './support_libs/css/completion-page-styles.css';

body {
  font-family: 'Titillium Web', sans-serif;
  background-color: rgb(251, 251, 251);
  font-size: 16px;
  line-height: 22px;
}

input {
  outline:none;
}

.ant-input {
  color: rgba(0, 0, 0, 0.85);
}

.ant-input:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(87,168,233, .2);
  box-shadow: 0 0 0 2px rgba(87,168,233, .2);
}

.ant-input:hover {
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(87,168,233, .2);
  box-shadow: 0 0 0 2px rgba(87,168,233, .2);
}

/*.divider {*/
/*  height: 530px;*/
/*  width: 1px;*/
/*  background-color: rgba(0, 0, 0, 0.25);*/
/*}*/

.alemhealth-header-logo {
  min-height: 60px;
  background: url(./support_libs/media/alemhealth-header-logo.png) no-repeat left;
  margin-top: 10px;
  margin-left: 100px;
  background-color: rgb(251, 251, 251);
}

.desktop-home-box-header {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  line-height: 28px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: solid;
  font-family: 'Titillium Web', sans-serif;
}

.desktop-home-box-middle-text {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 19px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: solid;
  font-family: 'Titillium Web', sans-serif;
  text-align: center;
}

.desktop-home-box-footer {
  color: #4A4A4A;
  font-size: 12px;
  line-height: 22px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: solid;
  text-align: center;
  font-family: 'Titillium Web', sans-serif;
}

.radiologist-group-logo-container{
    padding: 78px 0px;
}

.radiologist-group-logo-top-container, .radiologist-group-logo-center-container, .radiologist-group-logo-bottom-container{
  display: flex;
  justify-content: center;
  align-items: center;
}

.radiologist-group-logo {
  height: 223px;
  width: 200px;
  background: url(./support_libs/media/radiologist-group-bg.png);
  background-size: cover;
  margin:-20px -15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.radiologist-group-logo__img{
  max-width: 50%;
}

.facility-logo__img{
  max-width: 50%;
}

.facility-logo {
  height: 112px;
  width: 100px;
  background: url(./support_libs/media/facility-bg.png);
  background-size: cover;
  margin:0px 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media(max-width:1024px){

  .radiologist-group-logo {
    height: 110px;
    width: 100px;
    background: url(./support_libs/media/radiologist-group-bg.png);
    background-size: cover;
    margin:-20px -15px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }


  .facility-logo {
    height: 56px;
    width: 50px;
    background: url(./support_libs/media/facility-bg.png);
    background-size: cover;
    margin:0px 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

.ant-carousel .slick-dots{
  position: relative !important;
  margin-top: 40px;
  pointer-events: none;
}