#sign-up {
  min-height: $min-vh-100;

  .ant-carousel .slick-list .slick-slide {
    pointer-events: auto !important;
  }
  .sign-up-login-link {
    padding: 10px !important;
    text-align: center;
    &-text{
      text-decoration: underline;
    }
  }

  .sign-up-left-panel {
    box-shadow:  0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    z-index: 222;
    @media (min-width: 767px) {
     padding: 48px !important;
    }

    @media (max-width: 480px) {
     padding: 12px !important;
    }
    .sign-up-logo {
      padding: 8px 12px 0;
      justify-content: space-between;
      align-items: center;

      @include media($md) {
        gap: 1em;
        padding-left: 0;
        padding-right: 0;
      }
    }

    .sign-up-info {
      margin-top: 75px;
      // padding: 0 12px;
      // min-height: 700px;
      height: calc(100% - 125px);
      position: relative;

      @include media-breakpoint-down($md) {
        // height: 900px;
        margin-top: 40px;
      }

    .ant-steps {
      position: absolute;
      top: -30px !important;
      z-index: 999;
      width: 100% !important;
      left: 0px;
      // padding: 0px 15px !important;

      .ant-steps-item {
        .ant-steps-dot {
          border: 1px solid $primary-color;
        }

        .ant-steps-item-icon {
          margin-left: 45px;
          margin-top: 0.8px;
          .ant-steps-icon-dot {
            background: $white !important;
            border: 2px solid rgba(0, 0, 0, 0.1);
          }
        }

        .ant-steps-item-tail {
          margin: 0 0 0 48px !important;
        }

        .ant-steps-item-tail::after {
          background: #f0f0f0;
          margin-left: unset !important;
          width: 100% !important;
        }

        .ant-steps-item-content {
          text-align: left !important;
          .ant-steps-item-title {
            font-size: 12px;
          }
        }
      }

      .ant-steps-item-active {
        .ant-steps-item-title {
          margin-top: -3px !important;
          font-weight: 600;
        }

        .ant-steps-item-icon {
          margin-top: 1.5px;
          .ant-steps-icon-dot {
            height: 8px !important;
            width: 8px !important;
            background: $white !important;
            border: 2px solid $primary-color;
          }
        }
      }

      @media (max-width: 480px) {
        &.ant-steps-horizontal.ant-steps-label-horizontal {
          display: flex;
          flex-direction: row;
        }
      }
    }      

      // .ant-steps {
      //   position: absolute;
      //   top: -30px;
      //   .ant-steps-item {
      //     flex: 0 1 auto;
      //     margin-right: 0;
      //     .ant-steps-item-icon {
      //       width: 57px;
      //       height: 3px;
      //       border-radius: 4px;
      //       background: #BDBDBD;
      //       border: #BDBDBD;
      //       .ant-steps-icon {
      //         display: none;
      //       }
      //     }
      //     .ant-steps-item-content {
      //       display: none;
      //     }
      //   }
      //   .ant-steps-item-active {
      //     .ant-steps-item-icon {
      //       border: #15a9e1;
      //       background: #15a9e1;
      //     }
      //   }
      //   @media (max-width: 767px){
      //     top: -15px;
      //   }
      //   @media (max-width: 480px){
      //     top: -15px;
      //     &.ant-steps-horizontal.ant-steps-label-horizontal {
      //         display: flex;
      //         flex-direction: row;
      //     }
      //   }
      // }

      .ant-carousel {
        height: $h-100;
        
        .slick-slider {
          height: $h-100;

          .slick-slide {
            overflow: hidden;
          }
        }

        .slick-dots {
          position: absolute !important;
          margin: 0;
          display: flex;
          justify-content: flex-start;
          top: -30px;

          li:nth-child(n + 6) {
            display: none;
          }

          li {
            width: 57px;
          }

          li button {
            background-color: #BDBDBD;
          }

          li.slick-active button {
            background-color: $primary-color;
          }

          li button.radiology-group-last-dot-btn {
            background-color: $primary-color;
            opacity: 1;
          }

          @include media-breakpoint-down($md) {
            top: -10px;
          }
        }
      }
    }
    .mobile-step-container {
      display: flex;
      align-items: center;
      width: 100%;
      .step-item {
        background: #f0f0f0;
        width: clamp(60px, 45%, 40%);
        height: 7px;
        margin-right: 3px;
      }
      .step-item-active {
        background: $primary-color;
        width: clamp(60px, 45%, 40%);
        height: 7px;
        margin-right: 3px;
      }
      .step-count-text {
        margin-left: 5px;
        display: flex;
        .current-item {
          font-weight: 600;
        }
      }
    }
  }

  .sign-up-right-panel {
    background-color: #F8F8F8;

    .sign-up-right-panel-bg-img {
      width: $h-100;
      background: url("../../support_libs/media/map-logo.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      display: flex;
      flex-direction: column;

      .sign-up-right-panel-bg-content {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .sign-up-right-panel-link {
        padding: 0 4px 0 12px;
      }

      .ant-carousel {
        height: calc(100% - 30px);

        .slick-slider {
          height: $h-100;

          .slick-slide {
            overflow: hidden;
          }
        }
      }

      @include media-breakpoint-down($md) {
        background: none;
      }
    }
  }
}