@media (min-width: 1200px)
.container {
    max-width: 1140px;
}

@media (min-width: 992px)
.container {
    max-width: 960px;
}

@media (min-width: 768px)
.container {
    max-width: 720px;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

div {
  display: block;
}

ul {
  list-style: none;
  padding-left: 15px;
  line-height: 22px;
}

.pb-34 {
  padding-bottom: 34px;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-28 {
  margin-top: 34px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-35 {
  margin-top: 35px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-15 {
  margin-bottom: 15px;
}

.ml-30 {
  margin-left: 30px;
}

.mr-15 {
  margin-right: 15px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mr-10 {
  margin-right: 10px;
}