$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1600px;

$white: #fff;
$black: #000;

$primary-color: #15a9e1;

$black_80:#070C0E;

$black_20:#545859;

$min-vh-100: 100vh;
$h-100: 100%;

$font-family-helvetica-neue: 'Helvetica Neue', sans-serif;
$font-family-roboto: Roboto, sans-serif;

@mixin media($width) {
  @media only screen and (max-width: $width) {
    @content;
  }
}

@mixin text($font-weight: initial, $font-size: initial, $line-height: initial, $color: #606060) {
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  color: $color;
}

@mixin backgroundImg($backgroundUrl: "../support_libs/media/map-logo.svg", $background-position: center) {
  background: url($backgroundUrl);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: $background-position;
}
