.colleagues-form {
  height: calc(100vh - 78px);

  .colleagues-form-img svg {
    width: 100%;
    height: 100%;
  }

  .colleagues-form-submit-btn {
    width: 350px;
    margin: 19px auto 0;

    @include media-breakpoint-down($lg) {
      width: 320px;
    }

    @include media-breakpoint-down($sm) {
      width: 290px;
    }

    .ant-btn {
      font-size: 14px;
    }
  }
}
