.completion-icon {
  text-align: center;
}
.completion-hero-title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  margin-top: 50px;
  margin-bottom: 20px;
  font-family: 'Titillium Web', sans-serif;
  text-align: center;
}

.completion-hero-description {
  color: rgba(0, 0, 0, 0.85);
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  margin-bottom: 20px;
  font-family: 'Titillium Web', sans-serif;
  text-align: center;
}

.completion-timer-text {
  color: rgba(0, 0, 0, 0.65);
  font-size: 18px;
  line-height: 25px;
  font-weight: 100;
  margin-bottom: 30px;
  font-family: 'Titillium Web', sans-serif;
  text-align: center;
}

.completion-button {
  padding: 5px 20px !important;
  font-size: 18px !important;
  line-height: 22px !important;
}