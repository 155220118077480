.verification-code-field {
  input {
    font-family: Roboto, sans-serif;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    margin: 4px;
    width: 36px;
    height: 36px;
    margin-left: 0px;
    font-size: 24px;
    font-weight: normal;
    text-align: center;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    background-color: $white;

    @include media-breakpoint-down(1276px) {
      width: 36px;
      height: 36px;
    }

    &:hover,
    &:focus {
      border-color: #3bc4ed;
      outline: 0;
      box-shadow: 0 0 0 2px rgba(87, 168, 233, .2);
    }
  }
}

.verification-code-field-invalid {
  input {
    font-family: Roboto, sans-serif;
    border-radius: 4px;
    border: 1px solid #f5222d;
    margin: 4px;
    margin-left: 0px;
    width: 36px;
    height: 36px;
    font-size: 24px;
    font-weight: normal;
    text-align: center;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    background-color: $white;

    @include media-breakpoint-down(1276px) {
      width: 36px;
      height: 36px;
    }

    &:hover,
    &:focus {
      border-color: #3bc4ed;
      outline: 0;
      box-shadow: 0 0 0 2px rgba(87, 168, 233, .2);
    }
  }
}