.select-user-type-form {
  display: flex;
  flex-direction: column;
  &-title{
    color: $black_80 !important;
    font-weight: 600 !important;
  }
  .ant-form-item-control-input {
    position: relative;
    display: flex;
    align-items: center;
    // height: 42px;
    // padding: 10px 0px 0px 0px;
  }
  .select-user-type-form-description {
    margin-top: 0!important;
    font-family: $font-family-helvetica-neue;
    font-size: 14px;
    font-weight: normal;
    color: $black_20 !important;
  }
  .ant-form-item {
    // margin-bottom: 8px !important;
    margin-bottom: unset !important;
    .ant-input, .ant-select {
      margin-bottom: 20px;      
    }
  }
  &.medicial-group-wrap {
    .ant-input, .ant-select {
      margin-bottom: 10px;
    }
  }
  &.medicial-group-wrap {
    .ant-form-item {
      margin-bottom: 20px !important;
    }
  }
  .medical-group {
    margin-bottom: 20px;
  }
  .medical-button {
    width: 100%;
    border-radius: 4px;
  }
  .ant-radio-wrapper{
    margin-bottom: unset !important;
    padding: 5px 0px;
  }
}
