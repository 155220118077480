.create-account-form {
    &-title {
        color: $black_80 !important;
        font-weight: 700 !important;
    }

    .ant-form-item {
        margin-bottom: 24px !important;

        label {
            min-height: unset !important;
        }
    }

    .checkbox-form {
        margin-bottom: 10px !important;
        .ant-checkbox-wrapper {
            margin-bottom: 0px !important;
        }
    }
}

.verification-wrapper {
    .ant-form-item-label>label {
        min-height: unset;
    }
}