@import '~antd/dist/antd.less';

body {
  font-family: 'Helvetica Neue', sans-serif;
}

.ant-row {
  margin: 0!important;
}

// .ant-form-item {
//   margin-bottom: 24px!important;
// }

.ant-form-item-label > label {
  font-weight: 400;
  height: auto;
  min-height: 32px;
  white-space: initial;
  line-height: unset;
}

.ant-radio-wrapper {
  white-space: initial;
}

h2.ant-typography {
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 32px;
  color: @primary-color;
}

h3.ant-typography {
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 28px;
  color: @primary-color;
}

h4.ant-typography {
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: normal;
}

.vh-100 {
  height: 100vh;
}

.h-100 {
  height: 100%;
}

.text-primary {
  color: @primary-color;
}

.text-gray-3 {
  color: #828282;
}

.bg-white {
  background-color: #fff;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-grow-1 {
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.ant-form-item-with-help .ant-form-item-explain {
  color: #f5222d;
  font-size: 12px;
}

.ant-form-item-with-help .ant-input, .ant-form-item-with-help .ant-input:hover {
  background-color: #fff;
  border-color: #f5222d;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-capitalize {
  text-transform: capitalize;
}

.react-tel-input .form-control{
  width: 100% !important;
}

.required-icon {
  color: #f5222d;
}

.label {
  color: #070C0E;
  font-weight: 600;
}
.page-title {
  color: #070C0E !important;
  font-weight: 600 !important;
}
.wrong-otp-text{
  color: #f5222d;
  font-size: 12px;
  text-align: center;
}
.ant-tooltip {
  .ant-tooltip-content {
    background: rgba(0, 0, 0, 0.75) !important;
    width: 280px !important;
  }
  margin-bottom: -50px !important;
  .tool-tip-container {
    .title {
      margin-bottom: 10px;
    }
    .validation-icon-text{
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  .circle-icon {
    height: 14px;
    width: 14px;
    margin-right: 5px;
    border-radius: 50%;
    border: 2px solid #15a9e1;
  }
}

@primary-color: #15a9e1;@form-item-label-font-size: 12px;@label-color: #000;@typography-title-font-weight: 500;