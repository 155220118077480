/*@import '~antd/dist/antd.css';*/
@import url('https://fonts.googleapis.com/css?family=Noto+Sans');
@import './support_libs/css/layout-styles.css';
@import './support_libs/css/form-styles.css';
@import './support_libs/css/completion-page-styles.css';

body {
  font-family: 'Titillium Web', sans-serif;
  background-color: rgb(251, 251, 251);
  font-size: 16px;
  line-height: 22px;
}

input {
  outline:none;
}

.ant-input {
  color: rgba(0, 0, 0, 0.85);
}

.ant-input:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(87,168,233, .2);
  box-shadow: 0 0 0 2px rgba(87,168,233, .2);
}

.ant-input:hover {
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(87,168,233, .2);
  box-shadow: 0 0 0 2px rgba(87,168,233, .2);
}

/*.divider {*/
/*  height: 530px;*/
/*  width: 1px;*/
/*  background-color: rgba(0, 0, 0, 0.25);*/
/*}*/

.alemhealth-header-logo-mobile {
  min-height: 45px;
  background: url(./support_libs/media/alemhealth-header-logo.png) no-repeat center;
  margin: 2px 0 2px;
  background-color: rgb(251, 251, 251);
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.mobile-home-box-header {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  line-height: 28px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: solid;
  text-align: center;
  font-family: 'Titillium Web', sans-serif;
}

.desktop-home-box-middle-text {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 19px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: solid;
  font-family: 'Titillium Web', sans-serif;
  text-align: center;
}

.desktop-home-box-footer {
  color: #4A4A4A;
  font-size: 12px;
  line-height: 22px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: solid;
  text-align: center;
  font-family: 'Titillium Web', sans-serif;
}

.anchor-for-list-item {
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  display: inline;
  text-decoration: none;
}
.anchor-for-list-item:hover {
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  display: inline;
  text-decoration: none;
}
.anchor-for-list-item:focus {
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  display: inline;
  text-decoration: none;
}

.button-as-link {
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: #15a9e1;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  text-decoration: none;
}
.button-as-link:hover {
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: #15a9e1;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  text-decoration: none;
}
.button-as-link:focus {
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: #15a9e1;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.ant-modal-body {
  padding: 0px 0px !important;
}
.ant-list-item-meta-title {
  display: block;
}

.full-width-link {
  display: block !important;
  min-width: 100%;
}
/* 
.ant-list-item-meta {
  font-size: 30px;
} */
