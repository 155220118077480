.home-login {
  height: 100vh;

  &.qr-scanned {
    max-width: 420px;
    width: 100%;
    margin: auto !important;
    .home-login-right-col {
      .home-login-right-col-content {
        .home-login-right-col-header {
          .home-login-right-col-logo {
            width: 236px;
            height: 50px;
            margin: auto;
            background-position: center;
          }
        }
        .qr-scanned-button {
          width: 100%;
          margin-top: 10px;
          &.qr-scanned-button--no-margin {
            margin: 0;
          }
        }
        .home-login-right-col-body {
          .qr-scanned-faild {
            background: #FF00000A;
          }
          .qr-scanned-faild,
          .qr-scanned-success {
            padding: 30px;
            border: 1px solid #E8E8E8;
            border-radius: 4px;
            margin-top: 24px;
            text-align: center;
            .home-login-check-circle {
              display: block;
              margin-bottom: 10px;
            }
            .home-login-check-circle-img {
              height: 60px;
              width: 60px;
            }
            .qr-scanned-success-text {
              color: #52C41A;
              margin-bottom: 10px;
              display: block;
            }
            .qr-scanned-failed-text {
              color: #ff4140;
              margin-bottom: 10px;
              display: block;
            }
            .qr-scanned-success-desc {
              max-width: 275px;
              margin: auto;
            }
          }
        }
        .home-login-right-col-links {
          text-align: center;
        }
        .qr-scanned-new-user {
          margin: 10px 0;
          text-align: center;
          .qr-scanned-text-primary {
            color: #15a9e1
          }
          .qr-scanned-block {
            display: block;
            margin-top: 40px;
          }
        }
      }
    }
    .logo-image img{
      width: 236px;
      height: 50px;
      margin-left: 32px;

    }
    .professional-study{
      padding: 10px;
      border: 1px solid #E8E8E8;
      border-radius: 4px;
      margin-top: 12px;
    }
    .secure-study{
      padding: 10px;
      margin-top: 10px;
      text-align: center;
    }
    .secure-study-text {
      color: #4A4A4A99;
      font-weight: 700;
      font-size: 20px;
      line-height: 22px;
      margin-bottom: 10px;
      display: block;
    }
  }
  .home-login-right-col {
    background-color: $white;
    height: 100%;
    display: flex;
    justify-content: center;
    box-shadow:  0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;

    .home-login-right-col-content {
      // border: 1px solid red;
      box-sizing: content-box;
      padding: 54px 24px 24px;
      width: 336px;

      @include media($md) {
        width: 500px;
        padding: 15px;
      }

      @include media($sm) {
        text-align: center;
      }

      .home-login-right-col-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .home-login-right-col-logo {
          @include backgroundImg(
            $backgroundUrl: '../support_libs/media/alemhealth-header-logo.svg', $background-position: initial
          );
          width: 150px;
          height: 30px;
        }
      }

      .home-login-right-col-body {
        margin-bottom: 24px;
        %btn {
          @include text($font-weight: 600, $font-size: 14px, $line-height: 28px);
          letter-spacing: 0.02em;
          border-radius: 5px;
          height: 45px;
        }
        
        .home-login-right-col-background-img {
          display: none;
          height: 200px;
          margin: 36px 0 36px;

          @include media($md) {
            display: none;
          }

          @include media(300px) {
            height: 150px;
          }
        
          @include backgroundImg();
          width: 100%;
        }

        .home-login-right-col-title {
          // @include text($font-size: 28px, $line-height: 36px, $color: $black_80 );
          margin: 76px 0 0;
          @include media($md) {
            // margin-top: 0;
            text-align: left;
          }
        }

        .home-login-right-col-paragraph {
          color: #545859 !important;
          @include text($font-weight: normal, $font-size: 13px, $line-height: 24px);
          margin: 14px 0 26px;
          @include media($md) {
            text-align: left;
          }
        }

        .home-login-right-col-login-btn {
          @extend %btn;
          background: $primary-color;
          color: #fff;
        }

        .home-login-right-col-login-divider {
          padding: 0 24px;

          .ant-divider {
            background-color: unset;
            margin: 20px 0;

            .ant-divider-inner-text {
              @include text($font-weight: 500, $font-size: 12px, $line-height: 28px, $color: #c4c4c4);
              font-family: Roboto, sans-serif;
            }
          }
        }

        .home-login-right-col-create-account-btn {
          @extend %btn;
          border: 1px solid #b9b9b9;
        }

        .home-login-right-col-links {
          margin-top: 24px;
          a {
            @include text($font-weight: 600, $font-size: 12px, $line-height: 28px, $color: $primary-color);
            text-decoration: underline;
          }
        }
      }
    }
  }

  .home-login-main-col {
    background-color: #f2f2f2;
    height: 100%;

    .home-login-main-col-background-img {
      @include backgroundImg();
      width: 100%;
      max-width: 800px;
      height: 500px;
      margin: 100px auto 0;
    }

    @include media($md) {
      display: none;
    }
  }
}
.home-login-right-col-links-divider {
 border: 1px solid #606060;
 opacity: 0.6;
}
