.card-field {
  .card-field-item.ant-card {
    background-color: $white;
    border-radius: 3px;
    min-height: 250px;
    &.ant-card-bordered {
      border: 1px solid rgba(0, 0, 0, 0.4);
      &:not(.selected):hover {
        border-color: transparent;
      }
    }

    &.selected {
      border: 2.5px solid $primary-color;
      box-shadow: none;
    }

    .ant-card-body {
      padding: 12px;

      .card-field-item-body-img {
        margin-bottom: 10px;
      }

      .card-field-item-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 12px;
        .card-field-item-body-img {
          svg {
            height: 26px;
            width: 26px;
            display: block;
          }
        }
      }

      .card-field-item-body-title .ant-typography {
        font-family: $font-family-helvetica-neue;
        font-size: 16px;
        font-weight: normal;
        margin-bottom: 10px;
      }



      .card-field-item-body-description .ant-typography {
        font-family: $font-family-roboto;
        font-size: 12px;
        font-weight: normal;
        min-height: 110px;
      }
    }

    @include media-breakpoint-down($md) {
      min-height: auto;

      &.selected {
        border: 1px solid rgba(0, 0, 0, 0.4);
        .card-field-item-header {
          background: $primary-color;
        }
      }
      .ant-card-body {
        padding: 0;
        flex-grow: 1;
        .card-field-item-body-description {
          padding: 0 12px;
          .ant-typography {
            min-height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}
